const getTokenLogoURL = (address: string) =>{
  const lowerCaseAddress = address.toLowerCase();
  const xldAddresses = [
    "0x6b90a3975c7d66e118121883f5290635bb0f6a82", 
    "0x16fef73aba7dbdb0bc590cbff4063d1f3b251e71", 
    "0xc79d1fd14f514cd713b5ca43d288a782ae53eab2", 
    '0xb129e8b3b0e6660f3ffbd451cf0107e8a95450f8',
    '0x72923D7E9b9b37bfb2E13cb1885d26cE814fc803' 
  ]
  return xldAddresses.includes(lowerCaseAddress)?`/xld.svg`:`https://assets.trustwalletapp.com/blockchains/smartchain/assets/${address}/logo.png`
}

export default getTokenLogoURL
