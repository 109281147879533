import {
  ChevronDownIcon, Flex, Text
} from '@pancakeswap/uikit'
import ConnectWalletButton from 'components/ConnectWalletButton'
import Container from 'components/Layout/Container'
import CopyAddress from 'components/Menu/UserMenu/CopyAddress'
import { useTranslation } from 'contexts/Localization'
import useAuth from 'hooks/useAuth'
import React from 'react'
import { usePriceCakeBusd } from 'state/farms/hooks'
import styled from 'styled-components'
import useActiveWeb3React from '../../hooks/useActiveWeb3React'
import { Nav, NavBtnLink, NavDropdown, NavDropdownItem, NavLink, NavLogo, NavMenu } from './topbarElements'

const MarginTop = styled.div`
  margin-top: 80px;
`
const NavContainer = styled(Nav)`
  padding: 0;
`
const ContainerNavMenu = styled(NavMenu)`
  margin-left: auto;
  margin-right: 20px;
`
const HeaderButton = styled(NavBtnLink)`
  background: linear-gradient(90deg, #ffff3f 0%, #ffea00 65.03%, #ffdd00 100.01%);
  border-radius: 6px;
  color: #1e2537;
  font-weight: 500;
  border: none;
  min-height: 40px;
  line-height: 1.6;

  &:hover,
  &.active,
  &:focus {
    background-color: #ffdd00;
    color: #1e2537;
    font-weight: 500;
    border: none;
  }
`
const HeaderNavLink = styled(NavLink)`
  &:hover {
    color: #ffdd00;
  }
  &.active {
    color: #ffdd00;
    font-weight: 600;
  }
`

const HeaderAnchor = styled.a`
color: #fff;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
-webkit-text-decoration: none;
text-decoration: none;
padding: 0 1rem;
height: 100%;
cursor: pointer;
&:hover {
  color: #ffdd00;
}
&.active {
  color: #ffdd00;
  font-weight: 600;
}
`

const HeaderNav = styled.div`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  &:hover {
    color: #ffdd00;
  }
  &.active {
    color: #ffdd00;
    font-weight: 600;
  }
`
const XcelNavLogo = styled(NavLogo)`
  display: flex;
  align-items: center;
`
const LargeScreen = styled.div`
  display: none;
  ${({ theme }) => theme.mediaQueries.md} {
    display: inline-flex;
  }
`
const Menu = styled.div`
  margin-left: 0;
  ul {
    display: none;
  }
  position: relative;

  &:hover {
    ul {
      display: block;
    }
  }
  img {
    cursor: pointer;
  }
  ${({ theme }) => theme.mediaQueries.md} {
    margin-left: 20px;
    display:none;
  }
`
const MenuHolder = styled.ul`
  position: absolute;
  top: 100%;
  right: 0;
  width: 200px;
  height: auto;
  z-index: 1000;
`
const MenuList = styled.ol`
  padding: 15px 0px;
  background-color: #273047;
`
const Wrapper = styled(Flex)`
  align-items: center;
  // background-color: ${({ theme }) => theme.colors.dropdown};
  background-color: #262d41;
  border-radius: 16px;
  position: relative;
  padding: 12px;
  margin-right: 10px;
`
const TopBar: React.FC<any> = ({ children }) => {
  const { account } = useActiveWeb3React()
  const { currentLanguage, setLanguage, t } = useTranslation()
  const cakePriceUsd = usePriceCakeBusd()
  const { logout } = useAuth()

  return (
    <>
      <Container>
        <NavContainer>
          <XcelNavLogo to="/">
            <img src="/Xcelswap-2.svg" alt="logo" />
            <LargeScreen>
              <img src="/XcelSwap-1.svg" alt="logo" />
            </LargeScreen>
          </XcelNavLogo>
          {/* <NavLogo to="/"></NavLogo> */}
          {/* <Bars /> */}
          <ContainerNavMenu>
            {/* <HeaderNavLink to="/farms">Farm</HeaderNavLink> */}
            {/* <HeaderNavLink to="/swap">Exchange</HeaderNavLink> */}
            <HeaderAnchor href="https://stake.unifyre.io/xcellabbsc/info/0xe7725de3176ab2c781ad5af5e752fe09d24529b7" target="_blank">Staking</HeaderAnchor>
            <HeaderAnchor href="http://docs.xcelswap.com/" target="_blank">Docs</HeaderAnchor>
            {/* <HeaderNavLink to="/audit">Audits </HeaderNavLink> */}
            <NavDropdown >
              <span style={{display:"flex", alignItems: "center"}}>
                Audits  
                <ChevronDownIcon color="white" />
              </span>
              <NavDropdownItem>
                <HeaderNavLink to="/oxguard" style={{padding: "10px 0"}}>0xGuard </HeaderNavLink>
                <HeaderNavLink to="/antihackme" style={{ padding: "10px 0" }}>Antihack.me </HeaderNavLink>
              </NavDropdownItem>
            </NavDropdown>
            <HeaderAnchor href="https://bridge.xceldefi.com/" target="_blank">Bridge</HeaderAnchor>
          </ContainerNavMenu>
          {/* <LangSelector currentLang={currentLanguage.code} langs={languageList} setLang={setLanguage} /> */}
         
        
          {/*   {cakePriceUsd ? (
            <Flex alignItems="center" justifyContent="space-between" mr="3">
              <img src="xld.svg" alt="" style={{ marginRight: '5px' }} />
              <div>
                <Text small color="gray">
                  {t('XLD:')}
                </Text>
                <Text color="white">{`$${cakePriceUsd.toFixed(3)}`}</Text>
              </div>
            </Flex>
          ) : (
            <></>
          )} */}
          {!account ? <ConnectWalletButton /> : <CopyAddress account={account} />}
          <Menu>
            <img src="./images/menu.svg" alt="" />
            <MenuHolder>
              <MenuList>
                <HeaderAnchor  href="https://stake.unifyre.io/xcellabbsc/info/0xe7725de3176ab2c781ad5af5e752fe09d24529b7" target="_blank">Staking</HeaderAnchor>
              </MenuList>
              <MenuList>
                <HeaderAnchor href="http://docs.xcelswap.com/" target="_blank">Docs</HeaderAnchor>
              </MenuList>
              <MenuList>
                <HeaderNavLink to="/audit">Audit</HeaderNavLink>
              </MenuList>
              <MenuList>
                <HeaderAnchor href="https://bridge.xceldefi.com/" target="_blank">Bridge</HeaderAnchor>
              </MenuList>
              {/* <MenuList>
              {cakePriceUsd ? (
            <Flex alignItems="center" justifyContent="space-between" mr="3">
              <img src="xld.svg" alt="" style={{ marginRight: '5px' }} />
              <div>
                <Text small color="gray">
                  {t('XLD:')}
                </Text>
                <Text color="white">{`$${cakePriceUsd.toFixed(3)}`}</Text>
              </div>
            </Flex>
          ) : (
            <></>
          )}
              </MenuList> */}
         
            </MenuHolder>
          </Menu>
        </NavContainer>
      </Container>
      {children}
    </>
  )
}

export default TopBar
