import React, { useState } from 'react'
import styled from 'styled-components'
import { Text, PancakeToggle, Toggle, Flex, Modal, InjectedModalProps } from '@pancakeswap/uikit'
import { useAudioModeManager, useExpertModeManager, useUserSingleHopOnly } from 'state/user/hooks'
import { useTranslation } from 'contexts/Localization'
import { useSwapActionHandlers } from 'state/swap/hooks'
import usePersistState from 'hooks/usePersistState'
import useTheme from 'hooks/useTheme'
import QuestionHelper from '../../QuestionHelper'
import TransactionSettings from './TransactionSettings'
import ExpertModal from './ExpertModal'
import GasSettings from './GasSettings'

// TODO: Temporary. Once uikit is merged with this style change, this can be removed.
const PancakeToggleWrapper = styled.div`
  .pancakes {
    position: absolute;
  }
  .pancake {
    display:none;
  }
  .butter{
    width: 20px;
    height: 20px;
    background: white;
    top: 5px;
    left: 5px;
    position: absolute;
    border-radius: 100px;
    box-shadow: none;
    // -webkit-transform: scale(0);
    // -ms-transform: scale(0);
    // transform: scale(0);
    // -webkit-transition: 0.2s ease;
    // transition: 0.2s ease;
  }
`
const XcelModal = styled(Modal)`
  max-width: 420px;
  background: radial-gradient(
    156% 307.13% at 50.12% 25.71%,
    rgba(61, 69, 87, 0.474) 0%,
    rgba(43, 50, 69, 0.288) 100%,
    rgba(18, 23, 37, 0.396) 100%
  );
  border: 1px solid #535e7c;
  backdrop-filter: blur(30px);
  border-radius: 10px;
  & > div {
    border: none;
    h2 {
      color: #fff;
    }
  }
`

const SettingsModal: React.FC<InjectedModalProps> = ({ onDismiss }) => {
  const [showConfirmExpertModal, setShowConfirmExpertModal] = useState(false)
  const [rememberExpertModeAcknowledgement, setRememberExpertModeAcknowledgement] = usePersistState(false, {
    localStorageKey: 'pancake_expert_mode_remember_acknowledgement',
  })
  const [expertMode, toggleExpertMode] = useExpertModeManager()
  const [singleHopOnly, setSingleHopOnly] = useUserSingleHopOnly()
  const [audioPlay, toggleSetAudioMode] = useAudioModeManager()
  const { onChangeRecipient } = useSwapActionHandlers()

  const { t } = useTranslation()
  const { theme } = useTheme()

  if (showConfirmExpertModal) {
    return (
      <ExpertModal
        setShowConfirmExpertModal={setShowConfirmExpertModal}
        onDismiss={onDismiss}
        setRememberExpertModeAcknowledgement={setRememberExpertModeAcknowledgement}
      />
    )
  }

  const handleExpertModeToggle = () => {
    if (expertMode) {
      onChangeRecipient(null)
      toggleExpertMode()
    } else if (rememberExpertModeAcknowledgement) {
      onChangeRecipient(null)
      toggleExpertMode()
    } else {
      setShowConfirmExpertModal(true)
    }
  }

  return (
    <XcelModal title={t('Settings')} color="white" onDismiss={onDismiss}>
      <Flex flexDirection="column">
        <Flex pb="24px" flexDirection="column">
          <Text bold textTransform="uppercase" fontSize="12px" color="white" mb="24px">
            {t('Global')}
          </Text>
          <GasSettings />
        </Flex>
        <Flex pt="24px" flexDirection="column">
          <Text bold textTransform="uppercase" fontSize="12px" color="white" mb="24px">
            {t('Swaps & Liquidity')}
          </Text>
        </Flex>
        <TransactionSettings />
        {/* <Flex justifyContent="space-between" alignItems="center" mb="24px">
          <Flex alignItems="center">
            <Text>{t('Expert Mode')}</Text>
            <QuestionHelper
              text={t('Bypasses confirmation modals and allows high slippage trades. Use at your own risk.')}
              ml="4px"
            />
          </Flex>
          <Toggle id="toggle-expert-mode-button" scale="md" checked={expertMode} onChange={handleExpertModeToggle} />
        </Flex> */}
        {/* <Flex justifyContent="space-between" alignItems="center" mb="24px">
          <Flex alignItems="center">
            <Text>{t('Disable Multihops')}</Text>
            <QuestionHelper text={t('Restricts swaps to direct pairs only.')} ml="4px" />
          </Flex>
          <Toggle
            id="toggle-disable-multihop-button"
            checked={singleHopOnly}
            scale="md"
            onChange={() => {
              setSingleHopOnly(!singleHopOnly)
            }}
          />
        </Flex> */}
        <Flex justifyContent="space-between" alignItems="center">
          <Flex alignItems="center">
            <Text>{t('Flippy sounds')}</Text>
            <QuestionHelper
              text={t('Fun sounds to make a truly immersive pancake-flipping trading experience')}
              ml="4px"
            />
          </Flex> 
        <PancakeToggleWrapper>
            <PancakeToggle checked={audioPlay} onChange={toggleSetAudioMode} scale="md" />
          </PancakeToggleWrapper>
        </Flex>
      </Flex>
    </XcelModal>
  )
}

export default SettingsModal
