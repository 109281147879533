import React from 'react'
import styled from 'styled-components'
import { Spinner } from '@pancakeswap/uikit'
import Page from '../Layout/Page'

const Wrapper = styled.div`
  position: relative;
`

const Absolute = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: -90px;
  overflow: hidden;
  background-color: #273047;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const PageLoader: React.FC = () => {
  return (
    <Wrapper>
      {/* <Spinner /> */}
      <Absolute>
        <img style={{ height: '240px', width: '240px' }} src="./images/loader.gif" alt="" />
      </Absolute>
    </Wrapper>
  )
}

export default PageLoader
