import React from 'react'
import styled from 'styled-components'

const Height100 = styled.div`
 height: 100vh;
`
const Antihackme = (props: any) => {
    return <Height100>
        <iframe frameBorder="0" scrolling="no" title="audit"
            width="100%" height="100%"
            src="https://s3.ap-south-1.amazonaws.com/www.xcelswap.com/Smart+Contracts+Security+Audit+Report.pdf" />
    </Height100>
};

export default Antihackme;
