import React from 'react'
import styled from 'styled-components'
import { Card } from '@pancakeswap/uikit'

export const BodyWrapper = styled(Card)`
  border-radius: 0;
  min-width: 350px;
  z-index: 1;
  background: none;
  border-radius: 0;
  border: none;

  ${({ theme }) => theme.mediaQueries.md} {
    width: 460px;
  }
  & > div {
    background: none;
    border-top: 0px;
    border-radius: 0;
    border: none;
  }
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return <BodyWrapper>{children}</BodyWrapper>
}
