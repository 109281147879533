import React from 'react'
import { Button } from '@pancakeswap/uikit'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'contexts/Localization'
import styled from 'styled-components'
import { useWalletModal } from './uikitComponents/WalletModal'

const SwapButton = styled(Button)`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  background: linear-gradient(90deg, #ffff3f 0%, #ffea00 65.03%, #ffdd00 100.01%);
  border-radius: 6px;
  color: #1e2537;
  border: none;
  box-shadow: none;
`

const ConnectWalletButton = (props) => {
  const { t } = useTranslation()
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout)

  return (
    <SwapButton onClick={onPresentConnectModal} {...props}>
      {t('Connect')}
    </SwapButton>
  )
}

export default ConnectWalletButton
