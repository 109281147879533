import React from 'react'
import styled from 'styled-components'

const Height100 = styled.div`
 height: 100vh;
`
const OxGuardAudit = (props: any) => {
    return <Height100>
        <iframe frameBorder="0" scrolling="no" title="audit"
            width="100%" height="100%"
            src="https://s3.amazonaws.com/xcelpay.io/_xcelswapaudit/xcelswap-final-audit.pdf" />
    </Height100>
};

export default OxGuardAudit;
