import styled from 'styled-components'
import { Box } from '@pancakeswap/uikit'

const Card = styled(Box)<{
  width?: string
  padding?: string
  border?: string
  borderRadius?: string
}>`
  width: ${({ width }) => width ?? '100%'};
  border-radius: 16px;
  padding: 1.25rem;
  padding: ${({ padding }) => padding};
  background: radial-gradient(94.44% 470.74% at 5.56% 12.4%, rgba(43, 50, 68, 0.7) 0%, rgba(43, 50, 69, 0.48) 100%);
  backdrop-filter: blur(30px);
  border-radius: 10px;
`
// border: ${({ border }) => border};
// border-radius: ${({ borderRadius }) => borderRadius};
// background-color: ${({ theme }) => theme.colors.background};
export default Card

export const LightCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.colors.background};
  background: radial-gradient(94.44% 470.74% at 5.56% 12.4%, rgba(43, 50, 68, 0.7) 0%, rgba(43, 50, 69, 0.48) 100%);
`

export const LightGreyCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  background: radial-gradient(94.44% 470.74% at 5.56% 12.4%, rgba(43, 50, 68, 0.7) 0%, rgba(43, 50, 69, 0.48) 100%);
`

export const GreyCard = styled(Card)`
  background: radial-gradient(94.44% 470.74% at 5.56% 12.4%, rgba(43, 50, 68, 0.7) 0%, rgba(43, 50, 69, 0.48) 100%);
`
