import React from "react";
import { Svg, SvgProps } from "@pancakeswap/uikit";


const Icon: React.FC<SvgProps> = (props) => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width="35.000000pt" height="35.000000pt" viewBox="0 0 225.000000 225.000000"
      preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,210.000000) scale(0.090000,-0.090000)"
        fill="#5b48e1" stroke="none">
        <path d="M1085 2232 c-22 -9 -44 -22 -50 -28 -5 -7 -35 -24 -66 -39 -31 -15
  -56 -32 -57 -38 -6 -38 -13 -560 -8 -581 9 -36 37 -30 129 25 66 39 84 46 107
  39 32 -9 69 -27 90 -45 8 -7 44 -27 80 -46 36 -19 68 -38 71 -42 3 -4 15 -9
  27 -10 12 0 19 -3 17 -6 -6 -5 54 -43 72 -45 7 -1 24 -13 38 -26 l25 -23 0
  -236 c0 -130 -4 -242 -9 -249 -5 -8 -34 -28 -65 -46 -31 -18 -53 -36 -49 -41
  4 -4 3 -5 -3 -2 -12 7 -63 -18 -139 -68 -66 -43 -150 -85 -170 -85 -19 0 -398
  214 -414 234 -7 8 -11 169 -11 487 0 261 -3 509 -7 552 -6 73 -7 77 -29 77
  -13 0 -33 -9 -44 -20 -11 -11 -24 -20 -30 -20 -5 0 -17 -9 -28 -20 -10 -11
  -37 -26 -61 -35 -23 -8 -57 -25 -74 -37 -17 -12 -38 -23 -46 -25 -9 -1 -20 -8
  -26 -16 -5 -8 -21 -18 -35 -22 -14 -4 -39 -18 -56 -31 -17 -13 -34 -21 -36
  -19 -12 12 -67 -54 -76 -90 -6 -24 -12 -242 -14 -540 l-3 -500 24 -42 c30 -52
  38 -58 261 -185 102 -58 199 -114 217 -124 77 -46 362 -210 403 -232 77 -43
  109 -36 266 53 169 96 522 301 622 361 40 24 77 44 82 44 5 0 13 6 17 14 4 7
  18 19 31 25 13 7 31 31 41 54 16 39 17 80 14 537 -5 573 -3 555 -89 613 -30
  21 -58 37 -62 37 -5 0 -17 8 -28 18 -10 9 -49 32 -86 51 -38 18 -78 42 -89 52
  -12 11 -28 19 -36 19 -8 0 -11 4 -8 10 3 5 0 7 -8 4 -8 -3 -20 2 -27 11 -7 8
  -18 15 -26 15 -7 0 -25 11 -40 25 -15 14 -36 25 -46 25 -11 0 -17 4 -14 9 7
  11 -22 23 -36 14 -6 -3 -8 -1 -5 4 4 6 -7 16 -26 22 -17 7 -49 23 -70 37 -21
  13 -43 22 -49 18 -6 -4 -8 -3 -4 4 4 6 -11 19 -36 31 -44 21 -62 31 -110 63
  -15 10 -34 18 -41 18 -8 0 -20 7 -27 15 -15 18 -14 18 -65 -3z m56 -172 c11 0
  38 -12 62 -27 23 -15 67 -39 97 -55 30 -16 73 -40 95 -53 22 -14 47 -28 55
  -32 8 -3 23 -15 33 -25 10 -10 22 -16 28 -13 5 4 9 2 9 -3 0 -5 17 -17 38 -28
  20 -10 55 -31 78 -46 22 -15 53 -30 67 -34 15 -3 27 -10 27 -15 0 -5 7 -9 15
  -9 8 0 15 -4 15 -10 0 -5 5 -10 11 -10 6 0 17 -7 25 -15 9 -8 24 -15 34 -15
  11 0 24 -9 30 -20 6 -11 16 -20 23 -19 25 3 38 -1 32 -11 -3 -5 1 -10 9 -10
  10 0 16 -9 17 -22 4 -68 -4 -914 -8 -925 -3 -7 -60 -45 -127 -84 -66 -39 -137
  -82 -156 -94 -60 -38 -118 -65 -141 -65 -11 0 -35 8 -52 17 -83 45 -147 85
  -147 91 0 4 14 13 32 21 17 8 39 22 50 33 10 10 18 15 18 12 0 -3 17 4 38 16
  36 22 56 34 137 80 117 67 144 90 151 126 3 19 4 169 2 333 -3 237 -7 303 -18
  316 -16 19 -93 69 -160 103 -70 36 -114 63 -122 76 -4 6 -8 7 -8 3 0 -5 -7 -1
  -16 7 -8 9 -20 14 -25 11 -5 -4 -9 -3 -9 2 0 4 -26 21 -58 37 -31 17 -68 38
  -81 48 -32 24 -109 58 -132 58 -10 0 -21 5 -25 12 -6 9 -8 102 -4 231 0 5 5 5
  10 2 6 -3 13 2 17 11 4 11 8 13 11 6 2 -7 12 -12 23 -12z m-620 -372 c0 -13 0
  -213 -1 -446 -1 -326 2 -430 12 -453 7 -16 16 -29 19 -29 4 0 33 -17 65 -39
  33 -21 86 -53 119 -71 33 -18 96 -55 141 -81 45 -27 86 -49 93 -49 6 0 11 -3
  11 -8 0 -4 17 -16 38 -27 20 -11 88 -50 150 -87 62 -38 117 -68 121 -68 5 0
  14 -6 21 -14 13 -16 -12 -35 -126 -97 l-56 -30 -102 57 c-529 300 -689 396
  -703 424 -10 19 -13 122 -13 421 0 428 5 519 26 519 8 0 14 3 14 8 0 4 23 18
  51 32 28 13 58 32 67 42 25 25 52 23 53 -4z"/>
        <path d="M1079 1351 c-13 -10 -34 -21 -46 -25 -13 -4 -23 -11 -23 -16 0 -5
  -10 -12 -22 -15 -11 -4 -37 -21 -55 -39 l-35 -31 4 -108 c3 -102 5 -109 28
  -128 45 -35 175 -109 193 -109 27 0 166 76 202 111 17 16 20 36 23 127 l4 107
  -35 38 c-20 20 -45 37 -55 37 -11 0 -22 4 -24 8 -5 13 -99 62 -118 62 -9 0
  -28 -8 -41 -19z"/>
      </g>
    </svg>
  );
};

export default Icon;
